@use "~@angular/material" as mat;
@import "./theme.scss";
@import "https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined";
//@import "https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap";

@font-face {
  font-family: GothamRounded;
  src: url("/assets/font/GothamRounded-Medium.otf") format("opentype");
}

@include mat.core();
// @include mat.all-component-themes($my-theme);

html,
body {
  height: 100%;
  margin: 0;
  font-size: 14px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}
* {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.hidden {
  display: none;
}

.hand {
  cursor: pointer;
}

.mat-radio-label {
  margin-right: 10px !important;
}

.pull-right {
  float: right;
}

// Tables
.std-table-container {
  position: relative;
  min-height: 200px;
  flex-direction: column;
}

.std-table-container > div {
  /* these are the flex items */
  flex: 1;
}

.std-table-table-container {
  position: relative;
  //max-height: 540px;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.std-table-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.std-table-rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

/* Column Widths */
.mat-column-number,
.mat-column-state {
  max-width: 64px;
}

.mat-column-created {
  max-width: 124px;
}

td.mat-cell.action-col {
  width: 40px;
  padding-right: 0px;
  text-align: center;
}

mat-spinner::ng-deep {
  &.bt-spinner {
    display: inline;
    padding-right: 30px;
  }
}

.hand {
  cursor: pointer;
}

// hide arrows from input number
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
  margin: 0;
}

.mat-expansion-panel {
  margin-bottom: 1px !important;
  margin-top: 0px !important;
}

.mat-tooltip-info {
  background: #1f2b3de3;
  color: white !important;
  margin-right: 5px !important;
  font-size: 12px;
}

.mat-cell.logo {
  span {
    img {
      width: 100px;
      border: 1px solid lightgray;
      display: block;
    }
  }
}

.mat-mini-fab .mat-button-wrapper {
  line-height: 0px !important;
  color:white;
}

.tab-error-icon {
  margin-top: -10px;
}

tr.mat-header-row {
  background: white !important;
}

.actions-col {
  & button {
    background: transparent;
  }
}

.mat-chip.mat-standard-chip {
  color: rgba(0, 0, 0, 0.87);
}

.red-text {
  color: red;
}

.white {
  color: white;
}

// login form change color
.wrapper__form {
  .mat-focused .mat-form-field-label {
    /*focused change color of label*/
    color: white !important;
  }

  .mat-form-field-label {
    /*change color of label*/
    color: white !important;
  }

  .mat-form-field-infix {
    /*change color of input*/
    color: white !important;
  }

  .mat-form-field-underline {
    /*change color of underline*/
    background-color: white !important;
  }

  .mat-form-field-ripple {
    /*change color of underline when focused*/
    background-color: white !important;
  }
}

.icon-gray {
  color: white;
}

.mat-raised-button{
  color:white !important;
  margin:0px !important;
}

.mat-table{
  transition: none !important;
  box-shadow: none !important;
}

.bt-no{
  color:black !important;
  margin-right:10px !important;
}

.badge {
  font-size: 11px !important;
  font-weight: bold;
  height: 18px;
  line-height: 17px;
  color: #fff;
  padding: 3px 6px;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  text-shadow: none !important;
  text-align: center;
  width:90px;
  white-space: nowrap;

  &.beuzit{
    background-color: #f37020;
  }

  &.contact{
    background-color: #3a9da6;
  }

  &.new{
    background-color: #2f8189;
  }

  &.sent{
    background-color: #36adb8;
  }

  &.manual_dict, &.manual_resp{
    background-color: #599fce;
  }

  &.answered{
    background-color:#F2974B;
  }

  &.err_attch, &.err_pdf, &.err_content, &.err_xml, &.invalid, &.err_undef, &.err_nowork, &.noanswer, &.err_save, &.refused{
    background-color:#EC5353;
  }

  &.inprogress{
    background-color: #ecc45d;
  }

  &.validated , &.processed, &.finalized, &.accepted {
    background-color: #69B078;
  }

  &.concerned{
    background-color: #6db963;
  }

  &.not_concern{
    background-color: #32ad89;
  }

  &.renew{
    background-color: #b2cf63;
  }

  &.canceled{
    background-color: #b9b9b9;
  }

}